.home_container{
	padding-top: 3.5em;
	padding-right: 1em;
	padding-bottom: 3.5em;
	padding-left: 1em;
	overflow: auto;
	height: 100%;
}

.home_container + .action_btn button{
	background-color: transparent;
	border: none;
	margin-left: auto;

}

.home_container + .action_btn button svg{
	font-size: 1.3em;
}

.home_container + .action_btn {
	display: flex;
	align-items: center;
	padding: 4em 1.5em 2em 1.5em;
	position: sticky;
	left: 0;
	right: 0;
	top: 0;
	background-image: linear-gradient(transparent 20%, #f2f0e4 80%);
	bottom: 0;
}

.home_container + .action_btn > button:first-child{
	border-radius: 2em;
	background-color: #28ae61;
	color: #ffffff;
	padding: 0.3em 1em;
	font-size: 1.1em;
	font-weight: 400;
	display: flex;
	align-items: center;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.home_container + .action_btn > button:nth-child(2){
	line-height: 1;
}

.home_container + .action_btn > button:nth-child(2) svg{
	color: var(--textColor);
}