.DailyCard{
	background-color: #e7e4d5;
	padding: 0.5em 1em;
	border-radius: 1em;

}

.DailyCard > div{
	display: flex;
	align-items: flex-start;
	margin-bottom: 0.5em;

}

.DailyCard > div > span{
	width: 20%;
	text-align: end;
}

.DailyCard > div > div{
	margin-left: 0.5em;
	display: flex;
	flex-direction: column;
	width: 80%;

}

.DailyCard span{
	font-size: 0.675em;
	color: var(--textColor);
	font-weight: 500;

}

.DailyCard p{
	font-size: 0.875em;
	font-weight: 500;
	line-height: 1;
	margin: 0;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
	width: 195px;
	
}